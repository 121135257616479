.stock-management-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #343541;
  color: #fff;
  padding: 1rem;
  align-items: center;
}

.stock-management-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;  /* Ajustez la largeur maximale selon vos besoins */
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.product-name {
  font-size: 1.1em;
  text-align: left;
}

.title {
  font-size: 1.3em;
  font-weight: bold;
  text-align: left;
  margin-bottom: 1em;
}
