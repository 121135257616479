.qrCodeView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    height: 100vh;
    background-color: #343541;
    color: white;
    padding: 20px;
}

.qrCodeContainer {
    padding: 10px; /* Spacing around the QR code */
    background: white; /* Background color for the QR code */
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0px 0px 15px 5px rgba(255, 255, 255, 0.5); /* Outer glow effect */
    display: inline-block; /* To wrap the div tightly around the QR code */
}

.wallet-header {
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: left;
  background-color: #343541;
  color: #fff; 
}

.wallet-header h1 {
  margin: 0;
}

.qrCodeView h2 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 1.5em;
}

.qrCodeView p {
    margin-top: 10px;
    font-size: 1em;
    color: #F0EDEC;
}

.qrCodeView b {
    margin-top: 30px;
    font-size: 0.8em;
    color: #F0EDEC;
    word-break: break-all; /*casse la chaine hash si écran trop petit */
}

.qrCodeView span {
    margin-top: 5px;
    margin-bottom: 30px;
    padding-top: 20px;
    font-size: 2em;
    font-weight: bold;
    color: #fff
}

.qrCodeView button {
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;;
    background-color: #2563eb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.qrCodeView button:hover {
    background-color: #f2f2f2;
}

/* Styles for QRCode component */
.QRCode {
    width: 200px; /* Or any other size you want */
    height: 200px; /* Keep it same as width for square shape */
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .qrCodeView {
        padding: 10px;
    }

    .QRCode {
        width: 150px;
        height: 150px;
    }
}