.cartContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    background-color: #343541;
    color: white;
    padding: 20px;
    box-sizing: border-box;
    min-height: 100vh; /* 100% de la hauteur de l'écran */
}

.cart-header {
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: left;
  background-color: #343541;
  color: #fff;    
}

.cart-header h1 {
  margin: 0;
}

.cartHeader {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 1.5em;
  font-weight: bold;
}

.cartHeader div {
    font-size: 1.5em;
    font-weight: bold;
}

.cartItem {
    display: flex;
    align-items: start;
    justify-content: space-between;
    background-color: #2b2b2b;
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0px 0px 15px 5px rgba(255, 255, 255, 0.3);
}

.cartItemImage {
    margin-right: 2rem;
}

.cartItemImage img {
    max-width: 100px;
    border-radius: 10px;
}

.cartItemDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cartItemDetails h4 {
    margin: 0 0 10px 0; /* Augmenter légèrement l'espace sous le titre */
}

.cartItemDetails p {
    margin: 0 0 5px 0; /* Réduire la marge entre les éléments et augmenter l'espace entre titre, prix et quantité */
}

.cartItemActions {
    display: flex;
    justify-content: flex-start; /* Aligner les boutons avec le début du texte */
    padding-top: 0.5rem; /* Réduire la marge entre le bas de quantité et le haut des boutons */
}

.cartItem button {
    margin: 10px; /* Increase space around the button */
    width: 30px; /* Fixed width for a circle shape */
    height: 30px; /* Fixed height for a circle shape */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2563eb;
    border: none;
    border-radius: 50%; /* Make the button round */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cartItem button:hover {
    background-color: #f2f2f2;
    color: #343541;
}

.cartTotal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    background-color: #343541;
    color: #fff; 
}

.cartTotal h3 {
    margin: 0;
    font-size: 6rm;
    font-weight: bold;
}

.checkout-btn {
    width: 80%;
    margin-top: 1rem;
    align-items: center;
    padding: 10px 20px;
    font-size: 1em;
    font-weight: bold;
    color: #fff;
    background-color: #2563eb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.checkout-btn:hover {
    background-color: #f2f2f2;
    color: #343541;
}

@media (max-width: 768px) {
    .cartContainer {
        padding: 10px;
        align-items: stretch;
    }
    
     .cartItem {
        width: 80%; /* Faire en sorte que cartItem occupe 80% de la largeur de l'écran */
        margin-left: auto; /* Centre cartItem horizontalement */
        margin-right: auto; /* Centre cartItem horizontalement */
    }

    .cartItem img {
        max-width: 80px;
        margin-right: 1rem;
    }
}


