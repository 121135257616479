.ordersListContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    background-color: #343541;
    color: white;
    padding: 20px;
    padding-top: 15px;
    box-sizing: border-box;
    width: 100%; /* Ensure it occupies full width */
    min-height: 100vh; 
}

.ordersListContainer h2 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 1.5em;
}

.list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  background-color: #343541;
  color: #fff;    
}

.cart-button {
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.cart-item-count {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #2563eb;
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: bold;
}

/* Pour la section History */
.historyHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: #2b2b2b;
  color: white;
  border-radius: 10px; /* mêmes angles que la carte */
  margin: 1.5rem auto; /* même marge que la carte */
  max-width: 600px;
  width: 90%; /* s'adapte en responsive */
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.3); /* petit halo blanc */
}

.historyTitle {
  font-size: 1.2rem;
}

.historyArrow {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
    .ordersListContainer {
        padding: 10px;
        align-items: stretch;
    }
}
