.manSuccessPayment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    height: 100vh;
    background-color: #343541;
    color: white;
    padding: 20px;
}

.success-header {
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: left;
  background-color: #343541;
  color: #fff; 
}

.success-header h1 {
  margin: 0;    
}

.manSuccessPayment h1 {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 1.5em;    
}

.manSuccessPayment span {
    margin-top: 5px;
    margin-bottom: 30px;
    font-size: 2em;
    font-weight: bold;
    color: #fff
}

.manSuccessPayment button{
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;;
    background-color: #2563eb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.manSuccessPayment button:hover {
    background-color: #f2f2f2;
}