.clientSuccessPayment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    height: 100vh;
    background-color: #343541;
    color: white;
    padding: 20px;
}

.success-header {
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: left;
  background-color: #343541;
  color: #fff; 
}

.success-header h1 {
  margin: 0;    
}

.clientSuccessPayment h1 {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 1.5em;    
}

.clientSuccessPayment p {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 1.5em;    
}

.clientSuccessPayment span {
    margin-top: 5px;
    margin-bottom: 30px;
    font-size: 2em;
    font-weight: bold;
    color: #fff
}

.clientSuccessPayment button{
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;;
    background-color: #2563eb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.clientSuccessPayment button:hover {
    background-color: #f2f2f2;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid white; /* Ajoute une bordure supérieure blanche */
  border-bottom: 1px solid white;
}

th, td {
  padding: 8px;
  text-align: left;
  font-size: 1.2em;
}

th:last-child, td:last-child {
  text-align: right;
}

.info-box {
  padding: 5px;
  border-radius: 8px;
  background-color: #f3f4f6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

