/* EventsPage.css */
.event-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #343541;
  color: #fff;
  align-items: center;
  padding: 2rem;
}


.event-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    background-color: #343541;
    color: white;
    /*padding: 20px; */
}

.event-header-content {
  width: 90%; /* Default width for mobile */
}

.event-header p {
    font-weight: bold;
    font-size: 1.5em;
    padding: 20px;
}

.event-header h1 {
    font-size: 1.5em;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-left: 10px;  /* Ajoute une marge gauche de 10 pixels */
    margin-right: 10px; /* Ajoute une marge droite de 10 pixels */
}

.event-header h2 {
    font-size: 1.2em;
    font-weight: 250;
}

.share-buttons-container {
  background-color: #343541; /* Applique la même couleur de fond */
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 20px;
}

.event-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #343541;
  min-height: 100vh;
}

.map-container {
  margin: 1rem 0;
  width: 90%;
  max-width: 600px;
  display: flex; /* Ajoutez cela pour utiliser Flexbox */
  justify-content: center; /* Centre horizontalement le contenu */
  flex-direction: column; /* Organise les enfants verticalement */
}

.map-container button {
  padding: 1rem 2rem;
  margin: 0.5rem 0;
  border: none;
  border-radius: 0.3rem;
  background-color: #2563eb;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;    
  cursor: pointer;
  transition: background-color 0.3s;
  width: 80%;
  max-width: 300px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.map-container iframe {
    padding-bottom: 20px;
  }

@media (min-width: 768px) {
  .event-header-content {
    width: 60%; /* Adjust width for larger screens */
  }
}

@media (max-width: 768px) {
  .map-container iframe {
    width: 100%;
    height: 300px;
  }

  .event-button {
    padding: 0.8rem 1rem;
  }
    
  .event-header h2 {
    max-width: 80%; /* Sur les appareils mobiles, limite la largeur à 80% */
    text-align: justify;
    margin: 0 auto;  
  }
    
   .map-container button {
    padding: 0.8rem 1rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;   
  }  

}
