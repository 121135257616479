/* rewardsCard.css */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px; /* Ajouté pour l'espace vertical entre les coupons */
}

.coupon-card {
    /*background: linear-gradient(135deg, #2563eb, #90b0f5);*/
    background: #2563eb;
    color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
    position: relative;
    width: 80%; /* Largeur ajustée pour mobile */
    margin-bottom: 20px; /* Espace vertical ajusté entre chaque coupon */
}

.coupon-card h3 {
    font-size: 28px;
    font-weight: bold;
    line-height: 40px;
}

.coupon-card p {
    font-size: 15px;
    margin-bottom: 25px; /* Espace avant la row du code */
}


.circle1, .circle2 {
    background: #343541;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.circle1 {
    left: -25px;
}

.circle2 {
    right: -25px;
}


/* Adjustments for mobile screens */
@media (max-width: 768px) {
    .couponCard {
        width: 80%; /* Ensure the coupon card uses most of the screen width */
    }
}
