.stockAndProfitContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    height: 100vh;
    background-color: #343541;
    color: white;
    padding: 20px;
}

.stockAndProfitHeader {
    width: 100%;
    padding: 1rem 2rem;
    box-sizing: border-box;
    text-align: center; /* Changé à center pour mettre l'accent sur les titres */
    background-color: #343541;
    color: #fff;
}

.stockAndProfitHeader h1{
    margin: 0;
}

.stockAndProfitContainer h2 {
    margin-bottom: 15px;
    margin-top: 25px;
    font-size: 1.5em;
    padding: 10px 0; /* Ajouté pour espacer les titres */
}

.stockAndProfitContainer h3 {
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 1.5em;
    padding: 10px 0; /* Ajouté pour espacer les titres */
}

.stockAndProfitList {
    list-style-type: none; /* Supprime les puces */
    padding: 0;
}

.stockAndProfitList li {
    margin: 10px 0; /* Espacement entre les éléments de la liste */
    font-size: 1em;
}

