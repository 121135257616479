.reset-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #343541;
  color: #fff;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;    
  background-color: #343541;
  color: #fff;
}

.reset-header {
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: left;
  background-color: #343541;
  color: #fff; 
}

.reset-header h1 {
  margin: 0;
}

.error-message {
    color: #fff; /* Couleur du texte rouge foncé pour l'erreur */
    background-color: #FFD2D2; /* Couleur de fond rouge clair pour ressortir */
    border: 1px solid #D8000C; /* Bordure rouge foncé pour délimiter le message */
    padding: 10px; /* Espacement à l'intérieur du message pour le rendre plus lisible */
    margin: 10px 0; /* Marge au-dessus et en dessous du message pour le séparer du reste du contenu */
    border-radius: 5px; /* Coins arrondis pour un look moderne et doux */
    text-align: center; /* Centrer le texte du message */
    font-size: 0.9em; /* Taille du texte légèrement réduite pour ne pas surcharger */
}

.reset-form{
  max-width: 400px;
  width: 100%;
  display: flex; /* Ajouté pour le centrage */
  flex-direction: column; /* Ajouté pour le centrage */
  align-items: center; /* Ajouté pour le centrage */
  margin: 0 auto;   
    
}

.reset-form input {
  width: 80%;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  border: none;
  /*background: #2b2b2b; /* Couleur de fond pour les champs input */
  /*color: #fff; /* Couleur du texte pour les inputs */
  font-size: 1rem;
}

.reset-form button {
  width: 80%;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 0.3rem;
  border: none;
  background-color: #2563eb; /* Couleur de fond pour le bouton */
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reset-form button:hover {
  background-color: #1d4ed8; /* Couleur de fond au survol pour le bouton */
}

/* Responsive design pour grands écrans */
@media (min-width: 768px) {
  .reset-container {
    padding: 1rem;
  }

  .reset-form input,
  .reset-form button {
    width: 100%; /* Réinitialise la largeur à 100% pour les grands écrans */
  }
}