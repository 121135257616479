/* src/components/Animation.css */

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.animation-container {
  margin: 0 auto;
  width: 80vw;  /* 80% de la largeur de l'écran */
  height: calc(80vw * 474 / 510); /* Maintenir le ratio 504 x 468 */
  position: relative;
  overflow: hidden;
  margin-top: 40px;    
}

@media (min-width: 576px) {
  .animation-container {
    width: 60vw;  /* 60% de la largeur de l'écran pour les écrans moyens */
    height: calc(60vw * 474 / 510); /* Maintenir le ratio 504 x 468 */
    margin-top: 15px;  
  }
}

@media (min-width: 768px) {
  .animation-container {
    width: 510px;  /* Taille originale pour les écrans larges */
    height: 474px;
    margin-top: 15px;  
  }
}

.animation-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
}

.first-image {
  animation: slide-in 1s forwards;
}
