.spinner-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;    
  background-color: #343541;
  color: #fff;
}

.spinner-container p{
  color: #fff;
  margin-top: 20px;
  font-size: 1em;
  font-weight: bold;    
}