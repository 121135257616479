.landing-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #343541;
  color: #fff;
}

.landing-header {
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: left;
}

.landing-header h1 {
  margin: 0;
}

.landing-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-main h2 {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 2rem;
}

.landing-button {
  padding: 1rem 2rem;
  margin: 0.5rem 0;
  border: none;
  border-radius: 0.3rem;
  background-color: #2563eb;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;    
  cursor: pointer;
  transition: background-color 0.3s;
  width: 80%;
  max-width: 300px;
  text-align: center;
}

.landing-button:hover {
  background-color: #1d4ed8;
}

.landing-footer {
  padding: 1rem;
  text-align: center;
}

.landing-footer p {
  margin: 1rem 0;
}

.landing-footer div {
  margin-bottom: 1rem;
}

.landing-footer a {
  text-decoration: none;
  color: #fff;
  margin: 0 5px;
  cursor: pointer;
}

.landing-footer a:hover {
  text-decoration: underline;
}

.link-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0;
}

.link-button:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .landing-main h2 {
    font-size: 1.5rem;
  }

  .landing-button {
    padding: 0.8rem 1rem;
    width: 80%;
    margin: 10px 0;
  }

  .landing-header {
    padding: 1rem;
  }
    
  .landing-main h2 {
   font-size: 1rem;
  }    
}

