.lowBalance {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    height: 100vh;
    background-color: #343541;
    color: white;
    padding: 20px;
}

.lowBalanceHeader {
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: left;
  background-color: #343541;
  color: #fff; 
}

.lowBalanceHeader h1 {
  margin: 0;    
}

.lowBalance h1{
  margin-top: 20px;
  margin-bottom: 20px; 
  font-size: 2em;
  font-weight: bold;
  color: #fff       
}

.lowBalance p{
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 1.5em;
}

.lowBalance button{
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;;
    background-color: #2563eb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;       
}