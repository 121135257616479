.scanView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Utilisez center pour s'assurer que tout est bien aligné */
    text-align: center;
    height: 100vh; /* Peut-être réduire cette valeur si le contenu dépasse la hauteur de la vue */
    background-color: #343541;
    color: white;
    padding: 20px;
}

.scan-header {
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: left;
  background-color: #343541;
  color: #fff; 
}

.scan-header h1 {
  margin: 0;
}

.scanView h1{
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 1.5em; 
}

.scanResult{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    height: 100vh;
    background-color: #343541;
    color: white;
    padding: 20px;   
}

.scanResult p{
    margin-top: 30px;
    font-size: 0.8em;
    color: #F0EDEC;
    word-break: break-all;
}

.scanResult button{
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;;
    background-color: #2563eb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.scanResult button:hover {
    background-color: #f2f2f2;
}