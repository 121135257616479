.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.reset-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Assure que le container couvre toute la hauteur de l'écran */
  align-items: center; /* Centre horizontalement */
  justify-content: center; /* Centre verticalement */
  padding: 2rem;
  background-color: #343541;
  color: #fff;
}

.reset-header {
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: left;
  background-color: #343541;
  color: #fff;    
}

.reset-header h1 {
  margin: 0;
}

.error-message {
    color: #fff; /* Couleur du texte rouge foncé pour l'erreur */
    background-color: #FFD2D2; /* Couleur de fond rouge clair pour ressortir */
    border: 1px solid #D8000C; /* Bordure rouge foncé pour délimiter le message */
    padding: 10px; /* Espacement à l'intérieur du message pour le rendre plus lisible */
    margin: 10px 0; /* Marge au-dessus et en dessous du message pour le séparer du reste du contenu */
    border-radius: 5px; /* Coins arrondis pour un look moderne et doux */
    text-align: center; /* Centrer le texte du message */
    font-size: 0.9em; /* Taille du texte légèrement réduite pour ne pas surcharger */
}

.reset-input,
.reset-button {
  width: 80%;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  border: none;
  font-size: 1rem;
}

.reset-button {
  background-color: #2563eb;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reset-button:hover {
  background-color: #1d4ed8;
}

.reset-link {
  color: #fff;
  text-decoration: none;
  margin-top: 1rem;
  display: block; /* Garde le lien en dessous du bouton */
  cursor: pointer;
  text-align: center; /* Centre le lien */
}

.reset-link:hover {
  text-decoration: underline;
}

/* Responsive design pour grands écrans */
@media (min-width: 768px) {
  .reset-container {
    flex-direction: column; /* Garde la direction en colonne même sur grands écrans */
    justify-content: center; /* Assure le centrage vertical */
    align-items: center; /* Assure le centrage horizontal */
  }
  
  .reset-input,
  .reset-button {
    width: 50%; /* Ajustement optionnel de la largeur sur grands écrans pour un meilleur rendu visuel */
  }
  
  .reset-link {
    display: block; /* Assure que le lien reste sous le bouton */
    margin-top: 1rem;
    text-align: center; /* Garde le texte centré */
  }
}