.sales-search-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #343541;
  color: #fff;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.sales-search-container .MuiInputLabel-root {
  transform: translate(0, -25px) !important;
  color: #fff; /* ou toute autre couleur souhaitée */
}

.sales-search-container .MuiFormControl-root:first-child {
  margin-bottom: 25px;
}

.sales-search-input,
.sales-search-button {
  width: 80%;
  padding: 2rem;
  border-radius: 0.3rem;
  border: none;
  font-size: 1rem;
}

.sales-search-input {
  background-color: #fff; /* Fond blanc pour le champ de recherche */
  margin-top: 2em;
}

.sales-select-input {
  background-color: #fff;    
}
.sales-search-button {
  background-color: #2563eb;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sales-search-button:hover {
  background-color: #1d4ed8;
}

.sales-search-label {
  color: #fff; /* Texte du label en blanc */
  font-size: 1.1rem; /* Taille du texte légèrement plus grande pour le label */
  margin-bottom: 0.5rem; /* Espacement sous le label */
}

.sales-table-container {
  max-width: 1000px;
  width: 100%;
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .sales-search-container {
    justify-content: center; /* Toujours centré */
  }

  .sales-search-input,
  .sales-search-button {
    width: 33%; /* Limite la largeur à un tiers de l'écran sur grand écran */
  }
}
