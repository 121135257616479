.orderCard {
    display: flex;
    flex-direction: column;
    background-color: #2b2b2b;
    margin: 1.5rem auto; /* Adjusted to add margin on all sides */
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.3);
    color: white;
    max-width: 600px; /* Ensures maximum width is 60% on larger screens */
}


.orderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.orderItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1.2em;
}

.orderFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    font-style: italic;
}

.processButton {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #2563eb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.processButton:hover {
    background-color: #f2f2f2;
    color: #343541;
}

.processingOrder .orderCard {
    background-color: white;
    color: black;
    box-shadow: 0px 0px 15px rgba(0, 128, 0, 0.5);
}

.processingOrder .processButton {
    padding: 10px 20px;
    font-size: 1em;
    color: black;
    background-color: #AAFF00;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.progressBarContainer {
    width: 100%;
    height: 10px;
    background-color: #444;
    border-radius: 5px;
    margin: 0.5rem 0;
}

.progressBar {
    height: 100%;
    background-color: #2563eb; /* Couleur de la barre, à adapter si besoin */
    border-radius: 5px;
    transition: width 0.3s ease;
}

.orderTitle {
  display: flex;
  align-items: center;
  margin: 0.3rem 0;
  font-size: 1.2em; /* Taille plus modeste */
  font-weight: normal;
  color: white;
}

.timeIcon {
  margin-right: 0.3rem;
  font-size: 1.2em;
}

.progressBarGraduated {
  display: flex;
  height: 10px;
  background-color: #444;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.graduation {
  flex: 1;
  background-color: #444; /* segment vide */
  border-right: 1px solid #222;
  transition: background-color 0.3s ease;
}

.graduation:last-child {
  border-right: none;
}

.graduation.filled {
  background-color: #2563eb; /* segment rempli */
}

.progressBarGraduated {
    display: flex;
    height: 10px;
    background-color: #444;
    border-radius: 5px;
    overflow: hidden;
    margin: 0.5rem 0;
}

.graduation {
    flex: 1;
    background-color: #444; /* Segment vide */
    border-right: 1px solid #222;
    transition: background-color 0.3s ease;
}

.graduation:last-child {
    border-right: none;
}

.graduation.filled {
    background-color: #2563eb; /* Segment rempli */
}


    
@media (max-width: 768px) {
    .orderCard {
        flex-direction: column;
        width: 80%;
        margin: 0 auto; /* Centre card horizontalement */
        margin-bottom: 1.5rem;
        
    }
}
