.choice-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #343541; /* Même couleur que dans les autres pages */
  color: #fff;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.choice-header {
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: left;
  background-color: #343541;
  color: #fff; 
}

.choice-header h1 {
  margin: 0;
}

.choice-container h2{
  margin-bottom: 20px; 
  margin-top: 20px;
  font-size: 1.5em;    
}

.choice-container button{
  width: 80%;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 0.3rem;
  border: none;
  background-color: #2563eb;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Styles pour la modale */
.modal-container {
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #343541;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 5px rgba(255, 255, 255, 0.5);
  padding: 20px;
  z-index: 1000;
  width: 80%; /* Largeur de 80% sur mobile */
}

.modal-container h2 {
  margin-bottom: 20px;
}

.modal-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-container input {
  padding: 20px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.2rem;
}

.modal-container button {
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  background-color: #2563eb;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-container button:hover {
  background-color: #1d4ed8;
}

/* Responsive design pour grands écrans */
@media (min-width: 768px) {
  .choice-container {
    padding: 4rem; /* Augmentez la marge intérieure pour les grands écrans */
  }

  .choice-container button {
    width: 50%; /* Réduisez la largeur du bouton pour les grands écrans */
    font-size: 1.2rem; /* Augmentez la taille de la police pour les grands écrans */
  }
}

@media (min-width: 768px) {
  .modal-container {
    width: auto; /* Réinitialise la largeur à la largeur par défaut */
    max-width: 80%; /* Largeur maximale de 80% pour les grands écrans */
  }
}