
.productCard {
    display: flex;
    align-items: flex-start;
    background-color: #2b2b2b;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 15px;
    box-shadow: 0px 0px 15px 5px rgba(255, 255, 255, 0.3);
    color: white;
}

.productImage {
    flex-shrink: 0; /* Prevent image from shrinking */
    margin-right: 2rem;
}

.productImage img {
    width: 100px; /* Adjust width as needed */
    height: auto;
    border-radius: 10px;
}

.productInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content; /* Adjust height to fit content */
}

.rating {
    font-size: 0.9em;
    color: #ffd700;
    margin-bottom: 0.5rem;
}

.productName {
    font-size: 1.1em;
    margin-bottom: 0.5rem;
}

.price {
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 1rem;
}

.btn {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #2563eb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 0.5rem; 
}

.btn.disabled {
  background-color: #ffcccc; /* Rouge clair */
  color: #ffffff;            /* Texte en blanc */
  border: none;
  cursor: not-allowed;
  pointer-events: none;
}

.btn:hover {
    background-color: #f2f2f2;
    color: #343541;
}

.btn.added {
    background-color: #4caf50;
}

@media (max-width: 768px) {
    
    .productCard {
        flex-direction: row; /* Keep the same direction as larger screens */
        align-items: center; /* Align items vertically */
        width: 80%;
        margin-left: auto; /* Centre cartItem horizontalement */
        margin-right: auto; /* Centre cartItem horizontalement */
    }

    .productImage img {
        max-width: 80px; /* Optionally adjust the image size for mobile */
        margin-right: 1rem; /* Adjust spacing for mobile */
    }

    .productInfo {
        
        /* Adjust any additional styling for mobile here if needed */
    }
}


