.login-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #343541;
  color: #fff;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;    
  background-color: #343541;
  color: #fff;
}

.login-header {
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  text-align: left;
  background-color: #343541;
  color: #fff;    
}

.login-header h1 {
  margin: 0;
}

.login-form {
  max-width: 400px;
  width: 100%;
  display: flex; /* Ajouté pour le centrage */
  flex-direction: column; /* Ajouté pour le centrage */
  align-items: center; /* Ajouté pour le centrage */
  margin: 0 auto;
}

.error-message {
    color: #fff; /* Couleur du texte rouge foncé pour l'erreur */
    background-color: #FFD2D2; /* Couleur de fond rouge clair pour ressortir */
    border: 1px solid #D8000C; /* Bordure rouge foncé pour délimiter le message */
    padding: 10px; /* Espacement à l'intérieur du message pour le rendre plus lisible */
    margin: 10px 0; /* Marge au-dessus et en dessous du message pour le séparer du reste du contenu */
    border-radius: 5px; /* Coins arrondis pour un look moderne et doux */
    text-align: center; /* Centrer le texte du message */
    font-size: 0.9em; /* Taille du texte légèrement réduite pour ne pas surcharger */
}

.login-input,
.login-button {
  width: 80%;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  border: none;
  font-size: 1rem;
}

.login-button {
  background-color: #2563eb;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #1d4ed8;
}

.login-link {
  color: #fff;
  text-decoration: none;
  margin-top: 1rem;
  display: block; /* Garde le lien en dessous du bouton */
  cursor: pointer;
  text-align: center; /* Centre le lien */
}

.login-link:hover {
  text-decoration: underline;
}

/* Responsive design pour grands écrans */
@media (min-width: 768px) {
  .login-container {
    flex-direction: row;
    justify-content: space-around;
  }

  .login-form {
    margin: 0;
  }

  .login-input,
  .login-button {
    width: 100%; /* Réinitialise la largeur à 100% pour les grands écrans */
  }

  .login-link {
    display: inline-block; /* On larger screens, it should be next to the button */
    margin-left: 1rem; /* Space it out from the button */
    margin-top: 0;
    text-align: left; /* Réinitialise l'alignement du texte pour les grands écrans */
  }
}

