.listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    background-color: #343541;
    color: white;
    padding: 20px;
    box-sizing: border-box;
    min-height: 100vh; /* 100% de la hauteur de l'écran */
}

.listContainer h2 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 1.5em;
}

.list-header {
  display: flex;
  position: fixed; /* Fixe l'en-tête en haut de la page */    
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;
  background-color: #343541;
  color: #fff;    
}

.list-header h1 {
  margin: 0;
}

.cart-button {
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.cart-item-count {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #2563eb; /* La même couleur que le bouton 'add to cart' */
    color: white;
    border-radius: 50%;
    width: 25px; /* Largeur fixe pour la pastille */
    height: 25px; /* Hauteur fixe pour la pastille, identique à la largeur pour une forme ronde */
    display: flex;
    justify-content: center; /* Centrer horizontalement le contenu */
    align-items: center; /* Centrer verticalement le contenu */
    font-size: 1em;
    font-weight: bold;
    padding: 0; /* Pas de padding pour permettre le centrage correct */
}

.emptyRewards {
  width: 60%;
  max-width: 60%;
  margin: 0 auto; /* Centrer dans le conteneur parent */
}

.emptyRewards p {
  padding: 30px;
  font-size: 1.2em;
}

.emptyRewards button {
  width: 80%;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 0.3rem;
  border: none;
  background-color: #2563eb; /* Couleur de fond pour le bouton */
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

@media (max-width: 768px) {
    
    .listContainer {
        padding: 10px;
        align-items: stretch;
    }
    
    .emptyRewards {
    width: 85%;
    max-width: 85%;
    /* Le margin reste pour centrer */
  }
}